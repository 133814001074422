<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-8 mx-auto">
        <!--  -->
        <div class="card border-0 shadow-sm" v-if="orders.length === 0">
          <div class="card-body py-4 text-center">
            <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
            <h3>No orders found.</h3>
            <p>Orders placed with your practitioner will show here.</p>
          </div>
        </div>

        <div v-for="o in orders" :key="o.id">
          <div class="card border-0 shadow-sm mb-3">
            <div class="card-header mb-0 pb-0">

              <span
                v-if="o.status !== 'paid'"
                class="text-capitalize badge badge-danger p-1"
                style="position: absolute; right: 18px"
              >
                {{ o.status | formatStatus }}
              </span>
              <span
                v-else
                class="text-capitalize badge badge-primary p-1"
                style="position: absolute; right: 18px"
              >
                {{
                  o.status === 'paid' && o.api_status && o.api_status !== 'new'
                    ? o.api_status : o.status | formatStatus
                }}
              </span>

              <h4>
                Order #{{ o.id }} <small>(£{{ o.total.toFixed(2) }})</small>
              </h4>
              <p class="small mb-2 text-capitalize">
                Order Placed: {{ o.created_at | formatDate }}
              </p>
              <hr />
            </div>
            <div class="card-body py-2">
              <div class="row">
                <div class="col">
                  <ul class="list my-0">
                    <li v-for="i in o.items" :key="i.id" class="mb-1">
                      <span>{{ i.name | truncate(50) }}</span>
                      <span v-if="o.event_start">
                        - {{ o.event_start | formatDateTime }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer pt-0 text-right">
              <button
                class="btn btn-sm btn-outline-primary mb-2 mb-md-0"
                @click="viewDetails(o)"
                v-if="o.type === 'standard'"
              >
                <i class="fad fa-info mr-1" />
                Details
              </button>
              <button
                class="btn btn-sm btn-outline-primary mb-2 mb-md-0 ml-2"
                v-if="
                  client.clinic.allow_client_invoice_download &&
                  o.type !== 'standard' && o.status !== 'cancelled'
                "
                @click="viewInvoice(o)"
              >
                <i class="fad fa-download mr-1" />
                Invoice
              </button>
              <button
                class="btn btn-sm btn-primary mb-2 mb-md-0 ml-2"
                @click="makePayment(o)"
                v-if="o.status !== 'paid' && o.status !== 'cancelled'"
              >
                <i class="fad fa-credit-card mr-1" />
                Payment
              </button>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      orders: [],
      busy: true,
    };
  },
  computed: {
    client() {
      return this.$store.user;
    },
  },
  methods: {
    fetchClientOrders() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/orders")
        .then(({ data }) => {
          this.orders = data.orders;
          this.busy = false;
          if(this.$route.query && this.$route.query.id) {
            this.orders = this.orders.filter((order) => order.id == this.$route.query.id);
          }
        });
    },
    makePayment(order) {
      this.$router.push("/orders/" + order.id + "/card-payment");
    },
    viewDetails(order) {
      this.$router.push("/orders/" + order.id);
    },
    viewInvoice(order) {
      this.$router.push("/orders/" + order.id + "/invoice");
    },
  },
  mounted() {
    this.fetchClientOrders();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
    formatDateTime(date) {
      return moment.utc(date).local().format("lll");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
    truncate(str, len) {
      if (str.length > len) {
        return str.substring(0, len) + "...";
      } else {
        return str;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchClientOrders();
    next();
  },
};
</script>

<style>
</style>
